<template>
	<div class="index row-me flex1">
		<div class="flex1 row-me" style="flex-direction: column;">
			<div class="top-div row-me row-center">
				<!-- 展开收起 -->
				<!-- 	<img src="../assets/img/menu.png" class="margin-left20 pointer" title="合上" @click="clickClose"
					v-if="isOpened" />
				<img src="../assets/img/menu1.png" class="margin-left20 pointer" title="展开" @click="clickClose"
					v-else /> -->
				<img src="../assets/img/logoTop.png" class="margin-left30 imgLogo" alt="logo图" />
				<div class="flex1"></div>
				<div class="row-me row-center posRelative divR pointer">
					<el-image :src="user.avatar" :preview-src-list="[user.avatar]" fit="cover" class="img-header">
						<template #error>
							<img src="../assets/img/mrtx.png" slot="error"
								class="width100Per height100Per objFitCover" />
						</template>
					</el-image>
					<div class="title1 margin-left10 margin-right20" v-if="user">
						{{ userMoilie ? userMoilie : '未知用户' }}
					</div>
					<img src="../assets/img/xl.png" alt="" class="xlImg margin-right30" />
					<div class="quitBox posAbsolute" @click="clickExit">
						<div class="triangle"></div>
						退出登录
					</div>
				</div>


				<!-- <div class="line-shu"></div>
				<div class="row-me row-center margin-left20 pointer" @click="clickExit">
					<img src="../assets/img/exit.png" title="退出登录" class="img-icon" />
					<div class="title1 margin-left5 margin-right50" v-if="user">退出</div>
				</div> -->
			</div>
			<div class="row-me row-center flex1">
				<!--左侧布局-->
				<div class="left-div" :class="{ 'left-div-close': !isOpened }">
					<div class="posAbsoluteAll column-me row-center">
						<div class="menu-div flex1 column-me" v-dragscroll.y="true">
							<div class="width100Per">
								<el-menu active-text-color="#ffd04b" background-color="#2A3764"
									class="el-menu-vertical-demo" :collapse="!isOpened" :unique-opened="false"
									:default-active="defaultActive" text-color="#fff">
									<div v-for="(item,index) in menuArray" :key="index">
										<div class="twoTab" v-if="item.children.length>0">
											<el-sub-menu :index="index+''">
												<template #title>
													<img :src="item.icon" class="menu-icon">
													<span v-if="isOpened">{{ item.name }}</span>
												</template>
												<el-menu-item :index="index+'-'+index1"
													v-for="(item1,index1) in item.children" :key="index1"
													@click="clickMenu(item1)">
													{{ item1.name }}
												</el-menu-item>
											</el-sub-menu>
										</div>

										<div class="oneTab" v-else>
											<el-menu-item :index="index+''" @click="clickMenu(item)">
												<img :src="item.icon" class="menu-icon">
												<template #title>{{ item.name }}</template>
											</el-menu-item>
										</div>

										<!-- <el-sub-menu :index="index+''" v-if="item.children.length>0">
											<template #title>
												<img :src="item.icon" class="menu-icon">
												<span v-if="isOpened">{{ item.name }}</span>
											</template>
											<el-menu-item :index="index+'-'+index1"
												v-for="(item1,index1) in item.children" :key="index1"
												@click="clickMenu(item1)">
												{{ item1.name }}
											</el-menu-item>
										</el-sub-menu>
										<el-menu-item :index="index+''" @click="clickMenu(item)" v-else>
											<img :src="item.icon" class="menu-icon">
											<template #title>{{ item.name }}</template>
										</el-menu-item> -->
									</div>
								</el-menu>
							</div>
						</div>
					</div>
				</div>

				<!--右侧布局-->
				<div class="right-div column-me">

					<div class="row-me width100Per flex1" style="overflow: auto;">
						<div class="main-content flex1">
							<router-view></router-view>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- 修改密码 -->
		<el-dialog title="修改密码" v-model="visiblePassword" width="35%" top="10vh" :destroy-on-close="true">
			<div class="padding20">
				<div class="row-me row-center">
					<div class="width90 text-justify">当前账号</div>
					<div>：</div>
					<div class="flex1 margin-left10">{{ user.loginName }}</div>
				</div>
				<div class="row-me row-center margin-top20">
					<div class="width90 text-justify">原密码</div>
					<div>：</div>
					<div class="flex1">
						<el-input placeholder="请输入6-12位密码" style="width: 100%;" :type="pwdType0" clearable
							:minlength="6" :maxlength="12" v-model="password0"
							:class="{'red-border':showRedBorder&&password0===''}">
						</el-input>
					</div>
					<img :src="pwdType0==='password'?require('../assets/img/eye_icon.png'):require('../assets/img/not_eye_icon.png')"
						class="img-icon margin-left15 pointer" @click="clickPwd0">
				</div>
				<div class="row-me row-center margin-top20">
					<div class="width90 text-justify">新密码</div>
					<div>：</div>
					<div class="flex1">
						<el-input placeholder="请输入6-12位密码" style="width: 100%;" :type="pwdType1" clearable
							:minlength="6" :maxlength="12" v-model="password1"
							:class="{'red-border':showRedBorder&&password1===''}">
						</el-input>
					</div>
					<img :src="pwdType1==='password'?require('../assets/img/eye_icon.png'):require('../assets/img/not_eye_icon.png')"
						class="img-icon margin-left15 pointer" @click="clickPwd1">
				</div>
				<div class="row-me row-center margin-top20">
					<div class="width90 text-justify">确认新密码</div>
					<div>：</div>
					<div class="flex1">
						<el-input placeholder="请输入6-12位密码" style="width: 100%;" :type="pwdType2" clearable
							:minlength="6" :maxlength="12" v-model="password2"
							:class="{'red-border':showRedBorder&&password2===''}">
						</el-input>
					</div>
					<img :src="pwdType2==='password'?require('../assets/img/eye_icon.png'):require('../assets/img/not_eye_icon.png')"
						class="img-icon margin-left15 pointer" @click="clickPwd2">
				</div>
				<div class="row-me row-center flex-end1 margin-top100">
					<div class="btn-blue width140 btn-height40" @click="clickSave">提交</div>
					<div class="btn-grey width140 btn-height40 margin-left20" @click="clickCancel">取消</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		adminLogin
	}
	from "@/api/login/login.js"
	export default {
		components: {},
		data() {
			return {
				isOpened: true, //菜单展开
				menuArray: [], //左侧菜单
				user: {}, //当前登录用户
				userMoilie: "",
				visiblePassword: false,
				password0: '',
				password1: '',
				password2: '',
				pwdType0: 'password',
				pwdType1: 'password',
				pwdType2: 'password',
				showRedBorder: false,
				defaultActive: '0-0', //默认活跃的菜单
				isInitPage: false, //是否已经初始化第一个页面
			};
		},
		// html加载完成之前
		created() {
			let that = this
			setTimeout(() => {
				let token = sessionStorage.getItem('token')
				if (!token || token === '') {
					that.$router.replace({
						path: '/deviceManagement'
					})
				}
			}, 1000)
			let menuOpen = localStorage.getItem("menuOpen");
			this.isOpened = menuOpen === null || menuOpen === true || menuOpen === "true";
			this.getMenu()

		},
		// html加载完成后执行。
		mounted() {
			let user = JSON.parse(sessionStorage.getItem('user'))
			if (user && user !== '') {
				this.user = user
			}
			this.userMoilie = user.mobile
			// this.loginText()
		},
		// 事件方法执行
		methods: {
			//初始化选中菜单
			initMenu() {
				let path = this.$route.path //如："/sportsContract"
				for (let i = 0; i < this.menuArray.length; i++) {
					let menu = this.menuArray[i]
					if (menu.children.length > 0) { //有二级菜单
						for (let j = 0; j < menu.children.length; j++) {
							let child = menu.children[j]
							if (child.url === path) {
								this.defaultActive = i + '-' + j
							}
							if (!this.isInitPage && this.$route.path === '/index') {
								//默认显示第一个
								this.$router.push({
									path: child.url,
									query: {}
								})
								this.isInitPage = true
							}
						}
					} else { //无二级菜单
						if (menu.url === path) {
							this.defaultActive = i + ''
						}
						if (!this.isInitPage && this.$route.path === '/index') {
							//默认显示第一个
							this.$router.push({
								path: menu.url,
								query: {}
							})
							this.isInitPage = true
						}
					}
				}
			},
			//菜单点击
			clickMenu(item) {
				this.$router.push({
					path: item.url,
					query: {}
				})
			},
			clickPwd0() {
				this.pwdType0 = this.pwdType0 === 'password' ? 'text' : 'password'
			},
			clickPwd1() {
				this.pwdType1 = this.pwdType1 === 'password' ? 'text' : 'password'
			},
			clickPwd2() {
				this.pwdType2 = this.pwdType2 === 'password' ? 'text' : 'password'
			},
			//取消
			clickCancel() {
				this.visiblePassword = false
			},
			//提交
			clickSave() {
				let that = this
				if (this.password0 === '' || this.password1 === '' || this.password2 === '') {
					this.showRedBorder = true
					return
				}
				if (this.password1.length < 6) {
					this.$message.error('密码不能低于6位')
					return
				}
				if (this.password1 !== this.password2) {
					this.$message.error('两次新密码不一致')
					return
				}
				this.$postForm('/admin/adminUser/updateMyPassword', {
					oldPassword: this.password0,
					password: this.password1,
				}).then((res) => {
					if (res) {
						this.visiblePassword = false
						this.$message.success('修改成功，请重新登录')
						setTimeout(() => {
							that.$router.replace({
								path: '/login'
							})
						}, 1000)
					}
				}).catch((error) => {
					console.log(error)
				})
			},
			//退出登录
			clickExit() {
				this.$confirm('您确定要退出登录吗？', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					sessionStorage.removeItem('user')
					sessionStorage.removeItem('token')
					this.$router.replace({
						path: '/login',
						params: {}
					})
				}).catch(() => {

				});
			},
			//菜单展开合并
			clickClose() {
				this.isOpened = !this.isOpened;
				localStorage.setItem("menuOpen", this.isOpened);
			},
			//获取菜单
			getMenu() {
				this.$post('/admin/adminMenuTreeInfo', {}).then((res) => {
					if (res) {

						// 完整存本地
						var dataList = [];
						for (let item of res.data) {
							if (item.name == undefined) {
								item.children = [];
							}
						}
						dataList = res.data
						sessionStorage.setItem("menuArrayRegulate", JSON.stringify(dataList));
						console.log(this.menuArray, 'this.menuArraythis.menuArray')

						for (let item of res.data) {
							if (item.children == undefined) {
								item.children = [];
							}
							if (item.name == '文件管理') {
								item.children = [];
							}
						}
						console.log(res.data, 'res.data');

						this.menuArray = res.data;
						for (let item of this.menuArray) {
							item.icon = require('../assets/img/' + item.icon)
						}


						this.initMenu()
					}
				}).catch((error) => {
					console.log(error)
				})


				// this.menuArray = [{
				// 		icon: "icon_gzt.png",
				// 		name: "工作台",
				// 		url: "/stagIng",
				// 		children: []
				// 	},

				// 	{
				// 		icon: "icon_sbgl.png",
				// 		name: "设备管理",
				// 		url: "",
				// 		children: [{
				// 				children: [],
				// 				icon: "",
				// 				name: "设备管理",
				// 				url: "/deviceManagement",
				// 			},
				// 			{
				// 				children: [],
				// 				icon: "",
				// 				name: "设备应用分类",
				// 				url: "/appArrange",
				// 			},
				// 			{
				// 				children: [],
				// 				icon: "",
				// 				name: "报警日志",
				// 				url: "/alarmLog",
				// 			},
				// 		]
				// 	},
				// 	{
				// 		icon: "icon_khgl.png",
				// 		name: "客户管理",
				// 		url: "",
				// 		children: [{
				// 				children: [],
				// 				icon: "",
				// 				name: "客户列表",
				// 				url: "/customerList",
				// 			},
				// 			{
				// 				children: [],
				// 				icon: "",
				// 				name: "审核列表",
				// 				url: "/auditList",
				// 			},
				// 			{
				// 				children: [],
				// 				icon: "",
				// 				name: "行业分类管理",
				// 				url: "/industryClassification",
				// 			}
				// 		]
				// 	},
				// 	{
				// 		icon: "icon_wjgl.png",
				// 		name: "文件管理",
				// 		url: "/fileManagement",
				// 		children: []
				// 	},
				// 	{
				// 		icon: "icon_xtgl.png",
				// 		name: "系统管理",
				// 		url: "",
				// 		children: [{
				// 				children: [],
				// 				icon: "",
				// 				name: "系统用户",
				// 				url: "/systemUsers",
				// 			},
				// 			{
				// 				children: [],
				// 				icon: "",
				// 				name: "权限管理",
				// 				url: "/permissionManagement",
				// 			}
				// 		]
				// 	},
				// 	{
				// 		icon: "icon_yptpz.png",
				// 		name: "云平台配置",
				// 		url: "",
				// 		children: [{
				// 				children: [],
				// 				icon: "",
				// 				name: "数据接收日志",
				// 				url: "/receiveTransmission",
				// 			},
				// 			{
				// 				children: [],
				// 				icon: "",
				// 				name: "数据发送日志",
				// 				url: "/sendTransmission",
				// 			}
				// 		]
				// 	}
				// ]
				// for (let item of this.menuArray) {
				// 	item.icon = require('../assets/img/' + item.icon)
				// }
				// this.initMenu()
			},
		},
		// 计算属性
		computed: {},
		// 侦听器
		watch: {
			$route: {
				handler(newVal, oldVal) {
					this.initMenu()
				},
				deep: true
			}
		},
	}
</script>

<style lang="scss" scoped>
	.index {
		width: 100vw;
		height: 100vh;
		font-size: 18px;
		color: #222222;

		.el-menu {
			border: none;
		}

		.is-active {
			background-color: #212D57;
		}

		/deep/ .el-sub-menu__title {
			font-size: 18px;
		}

		.el-menu-item {
			font-size: 18px;
			color: #fff;
			background-color: #2A3764;
		}

		.is-opened .el-menu .el-menu-item {
			color: rgba(255, 255, 255, 0.5);
			background: #212D57;
		}


		.is-opened .el-menu .is-active {
			background: rgba(61, 110, 226, 0.2);
			color: #fff;
		}

		.index .is-opened .el-menu .el-menu-item {
			// background: red;
		}

		.menu-div .is-active {
			background: rgba(61, 110, 226, 0.2);
			color: #fff;
			// border-left: 6px solid #1182FB;
		}

		.oneTab .is-active {
			border-left: 6px solid #1182FB;
		}

		.twoTab .el-sub-menu .el-menu .is-active {
			border-left: 6px solid #1182FB;
		}

		.index .is-opened .el-menu .is-active {
			border-left: 6px solid #1182FB;
		}

		.el-sub-menu .el-menu-item {
			font-size: 17px;
			padding-left: 50px !important;
			padding-right: 0 !important;
			min-width: auto;
		}

		.el-menu--collapse {
			width: 100%;
		}

		/deep/ .el-menu-item * {
			vertical-align: middle;
		}

		.left-div {
			width: 260px;
			height: 100%;
			background: #2A3764;
			position: relative;
			transition: 0.3s;

			&-close {
				width: 80px;
			}

			.menu-icon {
				width: 22px;
				height: 22px;
				object-fit: scale-down;
				margin-right: 8px;
				display: inline;
			}

			.img-bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}

			.img-logo {
				width: 50%;
				margin-top: 10%;
				border-radius: 50%;
			}

			.img-logo1 {
				width: 70%;
				margin-top: 20px;
				border-radius: 50%;
			}

			.img-hong {
				width: 140px;
				height: 165px;
				object-fit: scale-down;
				user-select: none;
				pointer-events: none;
			}

			.menu-div {
				width: 100%;
				margin-top: 20px;
				overflow: auto;
				align-items: flex-end;
				/* Firefox */
				scrollbar-width: none;
				/* IE 10+ */
				-ms-overflow-style: none;

				/* Chrome Safari */
				&::-webkit-scrollbar {
					display: none;
				}

				.item-menu {
					width: 100%;
					height: 120px;
					cursor: pointer;

					&_active {
						background: #212D57;
					}

					&:hover {
						background: #212D57;
					}

					.img-menu {
						width: 30px;
						height: 30px;
						object-fit: scale-down;
					}

					.menu-text {
						font-size: 18px;
						color: rgba(255, 255, 255, 0.5);
						word-break: keep-all;
						text-overflow: ellipsis;
						margin-top: 10px;

						&-active {
							color: #FFFFFF;
						}
					}
				}
			}
		}

		.top-div {
			width: 100%;
			height: 66px;
			background-image: url(../assets/img/bg.png);
			// background-color: #2A3764;
			background-size: 100%;

			.imgLogo {
				width: 306px;
				height: 34px;
			}

			.img-header {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}

			.title1 {
				height: 50px;
				line-height: 50px;
				text-align: center;
				font-size: 18px;
				color: #FFFFFF;
			}

			.xlImg {
				width: 18px;
				height: 12px;
			}

			.quitBox {
				top: 48px;
				right: 30px;
				width: 120px;
				height: 40px;
				line-height: 40px;
				background: #FFFFFF;
				box-shadow: 0px 0px 20px rgba(95, 103, 112, 0.2);
				border-radius: 4px;
				font-size: 18px;
				color: #3D6EE2;
				text-align: center;
				display: none;
			}

			.triangle {
				/* 三角 */
				width: 0;
				height: 0;
				border-left: 12px solid transparent;
				border-bottom: 12px solid #fff;

				transform: rotate(-135deg);
				-moz-transform: rotate(-135deg);
				-webkit-transform: rotate(-135deg);
				position: absolute;
				right: 10px;
				margin-top: -5px;
			}

			.divR:hover .quitBox {
				display: block;
			}

			.line-shu {
				width: 1px;
				height: 24px;
				background: #ECECEC;
			}

			.title {
				font-size: 20px;
				color: #222222;
			}

			.img-icon {
				width: 30px;
				height: 30px;
				object-fit: scale-down;
			}
		}

		.right-div {
			// width: 100vw;
			overflow: auto;
			width: calc(100vw - 260px);
			height: 100%;

			.second-menu-div {
				width: 160px;
				// width: 0;
				height: 100%;
				background: #ffffff;
				box-shadow: 5px 5px 10px #ccc;
				z-index: 10;
				overflow: auto;
				// animation: openMenu 0.2s 1 ease-out;
				transition: 0.3s;
				/* Firefox */
				scrollbar-width: none;
				/* IE 10+ */
				-ms-overflow-style: none;

				/* Chrome Safari */
				&::-webkit-scrollbar {
					display: none;
				}

				&-open {
					width: 160px;
				}

				.item-menu-two {
					height: 60px;
					line-height: 60px;
					text-align: left;
					padding-left: 20px;
					font-size: 18px;
					color: #666666;
					cursor: pointer;
					word-break: keep-all;
					text-overflow: ellipsis;

					&-active {
						background: #E8EEF9;
						font-weight: bold;
						// color: #215AA0;
					}

					&:hover {
						background: #E8EEF9;
					}
				}
			}

			@keyframes openMenu {
				0% {
					width: 0;
				}

				100% {
					width: 160px;
				}
			}

			.main-content {
				font-size: 18px;
				color: #252630;
				background: #F5F6F8;
				height: 100%;
				overflow: auto;
			}
		}
	}

	.user-info-text {
		color: #000000;
		font-size: 18px;
		cursor: pointer;

		&:hover {
			font-weight: bold;
		}
	}
</style>